<app-header-minimal></app-header-minimal>
<div class="flex flex-col justify-center items-center min-h-[500px] my-12">
  <div
    class="bg-dark-gradient bg-no-repeat bg-contain w-[70vw] min-h-[300px] rounded-xl relative overflow-hidden"
  >
    <div
      aria-hidden="true"
      class="absolute pointer-events-none -bottom-10 -left-20 right-0 bg-[url('/assets/images/bg-lines.png')] bg-contain bg-no-repeat h-2/6"
    ></div>
    <div
      aria-hidden="true"
      class="absolute pointer-events-none bg-no-repeat -top-5 left-0 -right-10 bg-[url('/assets/images/bg-lines.png')] bg-contain bg-right h-2/6"
    ></div>
    <form [formGroup]="userForm">
      <div
        class="relative py-10 px-16 flex flex-col items-center justify-center mx-auto"
      >
        <a [routerLink]="'/'">
          <img
            [src]="'./assets/images/logo-black.svg'"
            alt="Logo Ensina"
            class="w-100 mt-3 p-2"
          />
        </a>
        <!-- STEPS -->
        <app-loading-spinner
          *ngIf="hiddeCarrossel"
          class="absolute top-0 bottom-0 flex align-middle content-center"
        ></app-loading-spinner>
        <div
          #sliderRef
          class="keen-slider"
          [ngClass]="{ 'opacity-0 pointer-events-none': hiddeCarrossel }"
        >
          <div class="keen-slider__slide">
            <div
              class="flex flex-col items-end justify-center max-w-[450px] w-full mx-auto h-full"
            >
              <app-input
                class="w-full"
                label="E-mail"
                formControlName="email"
                [error]="formErrors.email"
              />
              <app-input
                class="w-full mt-2"
                label="Repita seu e-mail"
                formControlName="confirmEmail"
                [error]="formErrors.confirmEmail"
              />

              <div class="mt-10 align-bottom relative w-full text-center">
                <div class="flex items-center">
                  <div class="flex-grow h-[2px] bg-primary"></div>
                  <span class="px-2">Ou</span>
                  <div class="flex-grow h-[2px] bg-primary"></div>
                </div>

                <div class="flex gap-8 justify-center mt-5">
                  <!-- <button href="#" class="text-primary hover:text-black">
                    <facebook-circle-icon [size]="40" />
                  </button> -->
                  <a
                    [href]="environment.apiUrl+'/api/auth/google'"
                    class="text-primary hover:text-black"
                  >
                    <google-circle-icon [size]="40" />
                  </a>
                  <!-- <button href="#" class="text-primary hover:text-black">
                    <apple-circle-icon [size]="40" />
                  </button> -->
                </div>
              </div>
            </div>
          </div>

          <div class="keen-slider__slide">
            <div
              class="flex gap-10 items-end justify-center max-w-[750px] w-full mx-auto"
            >
              <div class="w-full grid grid-cols-2 gap-x-4 gap-y-0">
                <app-input
                  class="w-full"
                  label="Nome Completo"
                  formControlName="fullName"
                  [error]="formErrors.fullName"
                />
                <app-input
                  class="w-full"
                  label="Whatsapp"
                  formControlName="mobile"
                  [maskFunction]="maskPhone"
                  [error]="formErrors.mobile"
                />
                <app-input
                  class="w-full"
                  label="Nascimento"
                  formControlName="birthday"
                  type="date"
                  [error]="formErrors.birthday"
                />
                <app-select
                  class="w-full"
                  label="Identidade de gênero"
                  placeholder="Selecione..."
                  [options]="[
                    { value: 'male', label: 'Masculino' },
                    { value: 'female', label: 'Feminino' },
                    { value: 'non-binary', label: 'Não-Binário' },
                    { value: 'transgender', label: 'Transgênero' },
                    { value: 'gender-fluid', label: 'Gênero Fluido' },
                    { value: 'agender', label: 'Agênero' },
                    { value: 'bigender', label: 'Bigênero' },
                    { value: 'gender-queer', label: 'Gênero Queer' },
                    { value: 'other', label: 'Outro' },
                    {
                      value: 'prefer-not-to-say',
                      label: 'Prefiro não responder'
                    }
                  ]"
                  formControlName="gender"
                  [error]="formErrors.gender"
                />
                <app-input
                  class="w-full"
                  label="Senha"
                  type="password"
                  formControlName="password"
                  *ngIf="!provider"
                  [error]="formErrors.password"
                />
                <app-input
                  class="w-full"
                  label="Digite a senha novamente"
                  type="password"
                  formControlName="confirmPassword"
                  [error]="formErrors.confirmPassword"
                  *ngIf="!provider"
                />
                <app-input
                  class="w-full"
                  label="CPF"
                  formControlName="document"
                  [maskFunction]="maskCpf"
                  [error]="formErrors.document"
                />
              </div>
            </div>
          </div>

          <div class="keen-slider__slide">
            <div
              class="flex flex-col gap-4 items-end justify-center max-w-[750px] w-full mx-auto"
            >
              <div class="flex items-end gap-5 w-full">
                <app-input
                  class="w-full"
                  label="CEP"
                  formControlName="zipCode"
                />
                <div class="w-full mb-3">
                  <a
                    href="httos://google.com.br"
                    class="text-lg hover:text-primary"
                    >Não sabe seu cep?</a
                  >
                </div>
              </div>
              <div class="flex items-end gap-5 w-full">
                <app-input
                  class="w-2/5"
                  label="Logradouro"
                  formControlName="address"
                />
                <app-input
                  class="w-1/5"
                  label="Número"
                  formControlName="number"
                />
                <app-input
                  class="w-2/5"
                  label="Complemento"
                  formControlName="complement"
                />
              </div>
              <div class="flex items-end gap-5 w-full">
                <app-input
                  class="w-5/12"
                  label="Bairro"
                  formControlName="neighborhood"
                />
                <app-input
                  class="w-4/12"
                  label="Cidade"
                  formControlName="city"
                  [attrs]="{ readonly: true }"
                />
                <app-input
                  class="w-3/12"
                  label="Estado"
                  formControlName="state"
                  [attrs]="{ readonly: true }"
                />
              </div>
            </div>
          </div>

          <div class="keen-slider__slide" *ngIf="!provider">
            <div
              class="flex flex-col gap-0 items-center justify-center max-w-[350px] w-full my-20 mx-auto"
            >
              <div class="flex gap-2 justify-center w-ful">
                <app-input
                  class="w-2/10"
                  formControlName="code-1"
                  [attrs]="{ maxlength: 1, class: 'text-center uppercase px-0' }"
                />
                <app-input
                  class="w-2/10"
                  formControlName="code-2"
                  [attrs]="{ maxlength: 1, class: 'text-center uppercase px-0' }"
                />
                <app-input
                  class="w-2/10"
                  formControlName="code-3"
                  [attrs]="{ maxlength: 1, class: 'text-center uppercase px-0' }"
                />
                <app-input
                  class="w-2/10"
                  formControlName="code-4"
                  [attrs]="{ maxlength: 1, class: 'text-center uppercase px-0' }"
                />
                <app-input
                  class="w-2/10"
                  formControlName="code-5"
                  [attrs]="{ maxlength: 1, class: 'text-center uppercase px-0' }"
                />
                <app-input
                  class="w-2/10"
                  formControlName="code-6"
                  [attrs]="{ maxlength: 1, class: 'text-center uppercase px-0' }"
                />
              </div>
              <p *ngIf="formErrors.code" class="text-sm text-error">
                {{ formErrors.code[0] }}
              </p>
              <p
                class="leading-snug text-right self-end text-primary text-[13px] mt-1"
              >
                Não recebeu seu código? Clique aqui
              </p>
            </div>
          </div>

          <div class="keen-slider__slide">
            <div
              class="flex flex-col gap-0 items-center justify-center w-full mt-20 mx-auto"
            >
              <img
                src="./assets/images/confirmado-title.png"
                class="select-none pointer-events-none"
              />
              <p class="leading-snug text-center text-[16px] mt-1">
                Agradecemos pelo seu cadastro e confirmamos que está tudo certo.
              </p>
              <p class="font-semibold mt-5">
                Parabéns por investir no seu conhecimento.
              </p>
            </div>
          </div>
        </div>
        <div
          class="max-w-[600px] w-full mt-5"
          [ngClass]="{ 'opacity-0 pointer-events-none': hiddeCarrossel }"
        >
          <div class="h-[2px] w-full mx-auto bg-primary"></div>
          <div
            class="flex gap-1 justify-center items-center mt-5 text-left"
            *ngIf="slider"
            [ngClass]="{
              hidden: slider?.track?.details?.abs == slider?.track?.details?.max
            }"
          >
            <shield-icon class="text-primary" [size]="46" />
            <p class="leading-snug text-sm">
              Nós protegemos seus dados de acesso usando encriptação para prover
              segurança no nível de bancos.
            </p>
          </div>
          <a
            (click)="next()"
            *ngIf="slider?.track?.details?.abs < slider?.track?.details?.max"
            class="flex justify-between items-center mx-auto bg-primary py-4 px-10 rounded-xl w-fit cursor-pointer font-semibold text-[20px] mt-10 text-white min-w-80 text-left group hover:bg-black"
          >
            Próximo
            <div class="flex gap-1">
              <chevron-right-icon
                [size]="15"
                [ngClass]="{
                  'text-black group-hover:text-primary':
                    i == slider?.track?.details?.abs
                }"
                *ngFor="
                  let e of [].constructor(slider?.track?.details?.max);
                  let i = index
                "
              />
            </div>
          </a>
          <a
            [routerLink]="'/home'"
            *ngIf="slider?.track?.details?.abs == slider?.track?.details?.max"
            class="mx-auto bg-primary block py-4 px-10 rounded-xl w-fit cursor-pointer font-semibold text-[20px] mt-10 text-white min-w-80 text-center hover:bg-black"
          >
            Acessar plataforma
          </a>
          <p
            class="leading-snug mx-auto mt-5 mb-7 text-center"
            *ngIf="countdown !== null"
          >
            Ou em {{ countdown }} segundos você será redirecionado
          </p>
        </div>
      </div>
    </form>
  </div>
</div>
