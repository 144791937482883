import { Component, Input } from '@angular/core';

@Component({
  selector: 'tiktok-icon',
  template: `
    <svg
      [attr.width]="size"
      [attr.height]="size"
      [class]="class"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28.367 35.287"
    >
      <g>
        <path d="M17.869,1.033C19.651,1,21.42,1.019,23.188,1a9.517,9.517,0,0,0,2.383,6.135l0,0a9.2,9.2,0,0,0,5.738,2.63l.03,0v5.926a13.62,13.62,0,0,1-5.8-1.467l.089.04a16.483,16.483,0,0,1-2.261-1.407l.057.04c-.013,4.294.013,8.588-.027,12.867a11.794,11.794,0,0,1-1.858,5.83l.022-.036a9.973,9.973,0,0,1-8.024,4.72h-.015c-.134.007-.292.011-.451.011a9.329,9.329,0,0,1-5.144-1.552l.044.027A11.219,11.219,0,0,1,3,26.418l0-.048c-.027-.735-.04-1.471-.013-2.191C3.519,18.555,7.877,14.2,13.176,14.2a9.608,9.608,0,0,1,1.746.16l-.061-.009c.027,2.176-.054,4.353-.054,6.529a4.278,4.278,0,0,0-1.546-.285,4.709,4.709,0,0,0-4.4,3.367L8.847,24a6.139,6.139,0,0,0-.229,1.678,6.511,6.511,0,0,0,.04.718l0-.028a4.764,4.764,0,0,0,4.573,4.225c.066,0,.132,0,.2,0h-.01a4.519,4.519,0,0,0,3.757-2.347l.011-.021a3.526,3.526,0,0,0,.556-1.543v-.016c.136-2.632.082-5.25.095-7.882.013-5.926-.013-11.838.027-17.749Z" transform="translate(-2.97 -1.004)" fill="currentColor"/>
      </g>
    </svg>
  `,
  standalone: true
})
export class TikTokIconComponent {
  @Input() size: number = 30;
  @Input() class = '';
   
}