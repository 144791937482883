<div class="px-10 py-5">
  <div #sliderRef class="keen-slider">
    <div
      class="keen-slider__slide overflow-visible"
      *ngFor="let i of slides; let index = index"
    >
      <div
        class="group h-[150px] hover:scale-105 transition-all duration-100 ease-linear bg-gray-700"
      >
        <div
          [style.background-image]="'url(./assets/images/kitty.jpg)'"
          class="h-[150px] bg-no-repeat bg-cover rounded-md cursor-pointer relative"
        >
          <span
            class="inline-block bg-primary rounded-md text-white text-xs px-3 py-1 mt-1 ml-1"
          >
            Sugestão para você
          </span>
          <div
            class="absolute left-0 bottom-0 right-0 bottom-0 bg-gradient-to-t from-black via-[#000000aa] to-transparent h-16 opacity-0 group-hover:opacity-100 transition-all duration-200 ease-linear"
          ></div>
          <div class="absolute right-1 bottom-1">
            <span class="text-white text-[12] py-1 font-extralight">
              ORIGINAL
              <span class="ml-2 font-medium">ENSINA </span>
              <span class="text-[20px] mb-[-6px] inline-block align-bottom"
                >®</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
