<footer class="bg-background text-white pt-10 font-helvetica">
  <div
    class="container mx-auto flex justify-between items-center gap-20 text-center md:text-left my-6"
  >
    <div class="flex-grow-0">
      <div class="flex gap-3">
        <h3 class="select-none inline-block font-extralight">Lang:</h3>
        <button title="Português">
          <img src="./assets/images/brazil-flag.png" class="w-7" />
        </button>
        <button title="">
          <img src="./assets/images/spanish-flag-outline.png" class="w-7" />
        </button>
        <button title="">
          <img src="./assets/images/english-flag-outline.png" class="w-7" />
        </button>
      </div>
    </div>
    <div class="flex-grow-0">
      <div class="flex gap-3 items-center">
        <h3 class="select-none inline-block font-extralight">
          Site criptografado:
        </h3>
        <a title="">
          <img
            src="./assets/images/ssl.png"
            class="w-28 hover:brightness-150 cursor-pointer"
          />
        </a>
        <a title="">
          <img
            src="./assets/images/secured.png"
            class="w-28 hover:brightness-150 cursor-pointer"
          />
        </a>
        <a title="">
          <img
            src="./assets/images/pagarme.png"
            class="w-28 hover:brightness-150 cursor-pointer"
          />
        </a>
      </div>
    </div>
  </div>


  <div class="mt-8 pt-4 w-full">
    <img src="./assets/images/footer/rounded-bar.svg" class="w-full" />
  </div>
  <div class="text-center md:text-left bg-black pt-3 pb-5 -mt-2">
    <div
      class="container mx-auto relative text-gray-400"
    >
      <div class="flex gap-4 items-center font-light text-[15px] font-regular">
        <img src="./assets/images/logo-white.svg" class="w-30 mt-2" />
        Todos os direitos reservados
        <span class="mx-1">⎯</span>
        <a href="#" class="hover:text-primary">Sobre Ensina</a>
        <span aria-hidden="true">•</span>
        <a href="#" class="hover:text-primary">Dúvidas frequentes</a>
        <span aria-hidden="true">•</span>
        <a href="#" class="hover:text-primary">Privacidade</a>
        <span aria-hidden="true">•</span>
        <a href="#" class="hover:text-primary">Termos de uso</a>
      </div>
    </div>
  </div>
</footer>
