import { Component, Input } from '@angular/core';

@Component({
  selector: 'shield-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 46.121 36.25"
      [attr.width]="size"
      [attr.height]="size*0.78"
    >
      <defs>
        <filter
          id="a"
          x="12.879"
          y="7.066"
          width="33.243"
          height="29.184"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="b" />
          <feFlood flood-opacity="0.161" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(-1102 -584)">
        <path
          d="M18,1.5,4.5,7.5v9c0,8.325,5.76,16.11,13.5,18,7.74-1.89,13.5-9.675,13.5-18v-9Zm0,16.485H28.5C27.7,24.165,23.58,29.67,18,31.4V18H7.5V9.45L18,4.785Z"
          transform="translate(1097.5 582.5)"
          fill="currentColor"
        />
        <g
          transform="translate(1121 596)"
          fill="#fff"
          stroke="currentColor"
          stroke-width="1"
        >
          <circle cx="8.5" cy="8.5" r="8.5" stroke="none" />
          <circle cx="8.5" cy="8.5" r="8" fill="none" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 1102, 584)" filter="url(#a)">
          <path
            d="M17,9,9.438,16.563,6,13.125"
            transform="translate(18 6.19)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
        </g>
      </g>
    </svg>
  `,
  standalone: true,
})
export class ShieldIconComponent {
  @Input() size: number = 30;
  @Input() class = '';
}
