import API from "../auth/auth-interceptor.service";
import { BehaviorSubject, Observable } from 'rxjs';

export class ProfileService {
  private profileSubject = new BehaviorSubject<any>(null);

  async getProfile() {
    try {
      const response = await API.get("/api/user/profile");
      this.setProfile(response.data)
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async verifyEmail(code: string) {
    try {
      const response = await API.post(`/api/user/verify-email-code`, { code });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  setProfile(profile: any) {
    this.profileSubject.next(profile);
  }

  getProfileState(): Observable<any> {
    return this.profileSubject.asObservable();
  }
}
