import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from "../../../shared/components/header/header.component";
import { HeroCarouselComponent } from "../../../shared/components/hero-carousel/hero-carousel.component";
import { HoverSlideCarouselComponent } from "../../../shared/components/carousel/hover-slide-carousel/hover-slide-carousel.component";
import { FooterComponent } from "../../../shared/components/footer/footer.component";

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [RouterModule, HeaderComponent, HeroCarouselComponent, HoverSlideCarouselComponent, FooterComponent],
  templateUrl: './auth-home-page.component.html',
  styleUrl: './auth-home-page.component.scss'
})
export class HomePageComponent {

}
