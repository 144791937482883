import { Component, Input } from '@angular/core';

@Component({
  selector: 'youtube-icon',
  template: `
    <svg
      [attr.width]="size"
      [attr.height]="size"
      [class]="class"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35.392 24.774"
    >
      <g>
        <g>
          <g>
            <path
              d="M258.136,7298.963v-9.932c3.525,1.66,6.256,3.263,9.485,4.99-2.663,1.478-5.96,3.134-9.485,4.942m19.647-14.87a4.592,4.592,0,0,0-2.748-1.631c-3.243-.616-23.477-.617-26.719,0a4.705,4.705,0,0,0-2.35,1.189c-2.852,2.648-1.958,16.844-1.271,19.143a5.02,5.02,0,0,0,1.133,2.185,4.568,4.568,0,0,0,2.39,1.245c2.671.553,16.428.861,26.76.082a4.635,4.635,0,0,0,2.459-1.257c2.637-2.636,2.457-17.631.346-20.955"
              transform="translate(-244 -7282)"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </g>
        </g>
      </g>
    </svg>
  `,
  standalone: true,
})
export class YoutubeIconComponent {
  @Input() size: number = 30;
  @Input() class = '';
}
