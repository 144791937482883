<div class="flex flex-col">
  <div class="flex justify-center items-center gap-10 min-h-[650px]">
    <img [src]="'./assets/images/home/pc.png'" alt="Logo Ensina" class="w-[750px] mb-5" />
    
    <div class="flex flex-col items-center text-center text-[34px] text-white">
      <img [src]="'./assets/images/logo.svg'" alt="Logo Ensina" class="h-12 mb-5" />
      
      <p class="font-gabriella uppercase">As mentes</p>
      <p class="font-gabriella uppercase text-primary hover:scale-y-125 hover:scale-x-150 transition-all duration-300 ease-linear">mais criativas</p>
      <p class="font-gabriella uppercase">juntas em um</p>
      <p class="font-gabriella uppercase">único lugar</p>
      <a [routerLink]="'/signup'" class="bg-primary py-3 px-20 rounded-lg cursor-pointer font-semibold text-[18px] mt-10 group hover:bg-white hover:scale-110 transition-all duration-200 ease-linear">
        <span class="text-white group-hover:hidden">Cadastre-se</span>
        <span class="text-primary hidden group-hover:inline">Inscreva-se</span>
      </a>
      <a href='#' [routerLink]="'/login'" class="text-sm mt-8 cursor-pointer hover:text-primary transition-transform duration-200 ease-linear hover:scale-125">Ou faça Login</a>
      
    </div>
  </div>
</div>
