import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, Router, Routes } from '@angular/router';
import { AppComponent } from './app/app.component';

import { AuthLayoutComponent } from './app/layouts/auth-layout/auth-layout.component';
import { AuthHomePageComponent } from './app/pages/auth/auth-home-page/auth-home-page.component';
import { AuthLoginPageComponent } from './app/pages/auth/auth-login-page/auth-login-page.component';
import { AuthSignupPageComponent } from './app/pages/auth/auth-signup-page/auth-signup-page.component';
import { HomePageComponent } from './app/pages/internal/home-page/auth-home-page.component';
import { ProfileService } from './app/services/profile.service';
import { APP_INITIALIZER } from '@angular/core';
import { of } from 'rxjs';
import { AuthRecoveryPageComponent } from './app/pages/auth/auth-recovery-page/auth-recovery-page.component';

// Initialize profile data before bootstrapping
export function initializeApp(profileService: ProfileService, router: Router) {
  return () => {
    const authToken = localStorage.getItem("authToken");
    const currentRoute = router.url;
    const publicRoutes = ['/signup', '/login', '/recovery'];

    if (!authToken) {
      if (!publicRoutes.some((route) => currentRoute.startsWith(route)) && currentRoute!='/') {
        router.navigate(['/login']);
      }
      return Promise.resolve(null);
    }
    return profileService.getProfile().then(
      (profile) => {
        if(profile) {
          switch (profile.state) {
            case "FINISHED":
              if (publicRoutes.some((route) => currentRoute.startsWith(route))) {
                router.navigate(['/home']);
              }
              break;
            case "PENDING_VALIDATION":
              router.navigate(['/signup'], { state: { initialSlide: 3 } });
              break;
            case "UNFINISHED":
              router.navigate(['/signup'], { state: { initialSlide: 1 } });
              break;
          
            default:
              break;
          }
        }
      },
      (error) => {
        localStorage.removeItem("authToken")
        return of(null); // Continue with initialization
      }
    );
  };
}

const routes: Routes = [
  // Routes for Auth Layout
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      { path: '', component: AuthHomePageComponent },
      { path: 'login', component: AuthLoginPageComponent },
      { path: 'signup', component: AuthSignupPageComponent },
      { path: 'recovery/:code', component: AuthRecoveryPageComponent },
    ]
  },
  {
    path: 'home',
    component: HomePageComponent
  },
  
  // Routes for Internal Layout
  // {
  //   path: '',
  //   component: InternalLayoutComponent,
  //   children: [
  //     { path: 'dashboard', component: DashboardComponent },
  //     { path: 'profile', component: ProfileComponent },
  //     // Add other internal pages here
  //   ]
  // },

  // Redirect any unknown routes
  //{ path: '**', redirectTo: 'login' }
];

bootstrapApplication(AppComponent, {
  providers: [
    ProfileService,
    {
      provide: APP_INITIALIZER,
      useFactory: (profileService: ProfileService, router: Router) =>
        initializeApp(profileService, router),
      deps: [ProfileService, Router],
      multi: true,
    },
    provideRouter(routes)
  ]
}).catch(err => console.error(err));