import { Component, Input } from '@angular/core';

@Component({
  selector: 'google-circle-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      [attr.width]="size"
      [attr.height]="size"
      [class]="class"
      viewBox="0 0 41 41"
    >
      <g
        data-name="Elipse 4"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
      >
        <circle cx="20.5" cy="20.5" r="20.5" stroke="none" />
        <circle cx="20.5" cy="20.5" r="19.75" fill="none" />
      </g>
      <g transform="translate(8.143 8.143)">
        <path
          d="M13.044,10.646v4.007h5.8a5.73,5.73,0,0,1-5.694,4.218,6.326,6.326,0,1,1,0-12.653A5.732,5.732,0,0,1,17.261,7.8l2.952-2.952A10.4,10.4,0,0,0,13.044,2a10.544,10.544,0,1,0,0,21.088c8.857,0,10.755-8.33,9.911-12.336Z"
          transform="translate(-0.22 -0.248)"
          fill="currentColor"
        />
        <rect
          width="24"
          height="24"
          transform="translate(0.357 0.357)"
          fill="none"
        />
      </g>
    </svg>
  `,
  standalone: true,
})
export class GoogleCircleIconComponent {
  @Input() size: number = 30;
  @Input() class = '';
}
