import { Component, Input } from '@angular/core';

@Component({
  selector: 'facebook-circle-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      [attr.width]="size"
      [attr.height]="size"
      [class]="class"
      viewBox="0 0 42 41"
    >
      <g fill="none" stroke="currentColor" stroke-width="1.5">
        <ellipse cx="21" cy="20.5" rx="21" ry="20.5" stroke="none" />
        <ellipse cx="21" cy="20.5" rx="20.25" ry="19.75" fill="none" />
      </g>
      <g transform="translate(14.553 8.643)">
        <path
          d="M77.1,13.01h2.678V24.033a.394.394,0,0,0,.394.394h4.54a.394.394,0,0,0,.394-.394V13.062h3.078a.394.394,0,0,0,.391-.349l.467-4.058a.394.394,0,0,0-.391-.439H85.1V5.672c0-.767.413-1.156,1.227-1.156h2.318a.394.394,0,0,0,.394-.394V.4A.394.394,0,0,0,88.648,0H85.453c-.023,0-.073,0-.146,0a6.122,6.122,0,0,0-4,1.509,4.2,4.2,0,0,0-1.4,3.732V8.216H77.1a.394.394,0,0,0-.394.394v4.006A.394.394,0,0,0,77.1,13.01Z"
          transform="translate(-76.703)"
          fill="currentColor"
        />
      </g>
    </svg>
  `,
  standalone: true,
})
export class FacebookCircleIconComponent {
  @Input() size: number = 30;
  @Input() class = '';
}
