import { Component, Input } from '@angular/core';

@Component({
  selector: 'chevron-right-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      [attr.width]="size*0.58"
      [attr.height]="size"
      viewBox="0 0 7.081 12.384"
    >
      <path
        data-name="Icon ionic-ios-arrow-forward"
        d="M16.193,12.386,11.506,7.7a.881.881,0,0,1,0-1.25.892.892,0,0,1,1.254,0l5.309,5.306a.883.883,0,0,1,.026,1.22l-5.331,5.342a.885.885,0,0,1-1.254-1.25Z"
        transform="translate(-11.246 -6.196)"
        fill="currentColor"
      />
    </svg>
  `,
  standalone: true,
})
export class ChevronRightIconComponent {
  @Input() size: number = 30;
  @Input() class = '';
}
