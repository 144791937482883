export function maskPhone(value: string): string {
  value = value.replace(/\D/g, ""); // Remove tudo que não é número

  // Aplica a máscara de acordo com o comprimento do número
  if (value.length <= 2) {
    return `(${value}`; // Só o DDD parcial
  } else if (value.length <= 6) {
    return `(${value.slice(0, 2)}) ${value.slice(2)}`; // DDD + começo do número
  } else if (value.length <= 10) {
    return `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6)}`; // (XX) XXXX-XXXX
  } else {
    return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`; // (XX) XXXXX-XXXX
  }
  }
  