<app-header-minimal></app-header-minimal>

<div class="flex flex-col justify-center items-center min-h-[500px] my-12">
  <div
    #wrapper
    class="bg-dark-gradient w-[450px] min-h-[300px] rounded-xl relative overflow-hidden"
  >
    <div
      aria-hidden="true"
      class="absolute pointer-events-none -bottom-10 -left-20 right-0 bg-[url('/assets/images/bg-lines.png')] bg-contain bg-no-repeat h-2/6"
    ></div>
    <div
      aria-hidden="true"
      class="absolute pointer-events-none bg-no-repeat -top-5 left-0 -right-10 bg-[url('/assets/images/bg-lines.png')] bg-contain bg-right h-2/6"
    ></div>
    <div class="pt-10 px-16 flex flex-col items-center justify-center">
      <a routerLink="/">
        <img
          [src]="'./assets/images/logo-black.svg'"
          alt="Logo Ensina"
          class="w-100 mt-3 mb-1 p-2"
        />
      </a>
    </div>
    <form [formGroup]="userForm">
      <div
        *ngIf="expiredIn > now"
        class="relative pb-10 px-16 flex flex-col items-center justify-center"
      >
        <h1 class="w-full block text-left font-bold my-2">
          Redefinição de senha
        </h1>
        <app-input
          class="w-full mt-2"
          label="Nova senha"
          type="password"
          formControlName="password"
          [error]="formErrors.password"
        />
        <app-input
          class="w-full mt-2"
          label="Repita nova senha"
          type="password"
          formControlName="confirmPassword"
          [error]="formErrors.confirmPassword"
        />

        <button
          (click)="resetPassword()"
          [disabled]="sendPasswordStatus!=null"
          class="bg-primary py-4 px-20 rounded-lg cursor-pointer font-semibold text-[16px] mt-5 text-white w-full text-center"
        >
          Redefinir senha
        </button>
        <p class="mt-3" *ngIf="sendPasswordStatus=='finished'">Senha redefinida com sucesso!</p>
      </div>
    </form>
  </div>
</div>
