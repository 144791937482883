import { ChangeDetectorRef, Component } from '@angular/core';
import { InputComponent } from '../../../shared/components/form/input';
import { FacebookCircleIconComponent } from '../../../shared/components/icons/facebook-circle-icon';
import { ChevronRightIconComponent } from '../../../shared/components/icons/chevron-right-icon';
import { InstagramIconComponent } from '../../../shared/components/icons/instagram-icon';
import { CommonModule } from '@angular/common';
import { ShieldIconComponent } from '../../../shared/components/icons/shield-icon';
import { ElementRef, ViewChild } from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { GoogleCircleIconComponent } from '../../../shared/components/icons/google-circle-icon';
import { AppleCircleIconComponent } from '../../../shared/components/icons/apple-circle-icon';
import { SelectComponent } from '../../../shared/components/form/select';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { AddressService } from '../../../services/address.service';
import { z } from 'zod';
import { maskCpf } from '../../../shared/utils/masks/maskCpf';
import { maskPhone } from '../../../shared/utils/masks/maskPhone';
import { LoadingSpinnerComponent } from '../../../loading-spinner/loading-spinner.component';
import { ProfileService } from '../../../services/profile.service';
import { environment } from '../../../../environments/environment';
import { HeaderMinimalComponent } from "../../../shared/components/header-minimal/header-minimal.component";

const formSchema = z
  .object({
    email: z.string().email('E-mail inválido'),
    confirmEmail: z.string().email('E-mail inválido'),
    fullName: z
      .string()
      .min(4, 'Nome é obrigatório')
      .max(100, 'Nome muito grande'),
    birthday: z.string().min(1, 'Nascimento é obrigatório'),
    document: z.string().min(11, 'Documento inválido'),
    mobile: z.string().min(10, 'Telefone inválido'),
    gender: z.string().min(1, 'Gênero é obrigatório'),
    password: z.string().min(8, 'Minímo de 8 caracteres'),
    confirmPassword: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.confirmEmail !== data.email) {
      ctx.addIssue({
        code: 'custom',
        path: ['confirmEmail'],
        message: 'E-mails náo são iguais',
      });
    }
    if (data.confirmPassword !== data.password) {
      ctx.addIssue({
        code: 'custom',
        path: ['confirmPassword'],
        message: 'Senhas não são iguais',
      });
    }
  });

const codeFormSchema = z
  .object({
    'code-1': z.string(),
    'code-2': z.string(),
    'code-3': z.string(),
    'code-4': z.string(),
    'code-5': z.string(),
    'code-6': z.string(),
  })
  .superRefine((data, ctx) => {
    Object.keys(data).forEach((key) => {
      const typedKey = key as keyof typeof data;

      if (!data[typedKey]) {
        ctx.addIssue({
          code: 'custom',
          path: ['code'],
          message: 'Preencha todos os caracteres',
        });
      }
    });
  });

@Component({
  selector: 'app-auth-signup-page',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    InputComponent,
    SelectComponent,
    ShieldIconComponent,
    FacebookCircleIconComponent,
    InstagramIconComponent,
    ChevronRightIconComponent,
    GoogleCircleIconComponent,
    AppleCircleIconComponent,
    LoadingSpinnerComponent,
    HeaderMinimalComponent
],
  templateUrl: './auth-signup-page.component.html',
  styleUrl: './auth-signup-page.component.scss',
})
export class AuthSignupPageComponent {
  @ViewChild('sliderRef') sliderRef!: ElementRef<HTMLElement>;
  initialSlide: any = 1;
  slider: any = null;
  countdown: number | null = null;
  intervalId: any;
  userForm: FormGroup;
  provider: string | null = null;
  formErrors: any = {};
  hiddeCarrossel = false;
  maskCpf = maskCpf;
  maskPhone = maskPhone;
  environment = environment

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private addressService: AddressService,
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private cdRef: ChangeDetectorRef
  ) {
    this.userForm = this.fb.group({
      email: [''],
      confirmEmail: [''],
      fullName: [''],
      document: [''],
      mobile: [''],
      gender: [''],
      password: [''],
      confirmPassword: [''],
      birthday: [''],
      zipCode: [''],
      address: [''],
      number: [''],
      complement: [''],
      neighborhood: [''],
      city: [''],
      state: [''],
      'code-1': [''],
      'code-2': [''],
      'code-3': [''],
      'code-4': [''],
      'code-5': [''],
      'code-6': [''],
    });

    if (localStorage.getItem('authToken')) {
      this.initialSlide = window.history.state.initialSlide;
    } else {
      this.initialSlide = 0;
    }

    this.route.queryParams.subscribe(async (params) => {
      const code = params['code'];
      if (code) {
        this.hiddeCarrossel = true;
        this.provider = 'google';
        try {
          const callbackResponse = await this.authService.googleCallBack(code);
          localStorage.setItem(
            'authToken',
            callbackResponse.session.accessToken
          );
          profileService.getProfile().then((profile) => {
            if (profile.state == 'FINISHED') {
              this.router.navigate(['/home']);
            } else {
              this.userForm.patchValue({
                email: callbackResponse.user.email || '',
                confirmEmail: callbackResponse.user.email || '',
                fullName: callbackResponse.user.name || '',
                birthday: callbackResponse.user.birthday || '',
                gender: callbackResponse.user.gender || '',
              });
              this.initialSlide = 1;
              this.slider.update(undefined, 1);
              this.provider;
            }
          });
        } catch (error) {
          this.router.navigate(['/signup']);
          this.provider = null;
          console.log(error);
        }
        setTimeout(() => {
          this.hiddeCarrossel = false;
        }, 250);
      }
    });
  }

  startCountdown(seconds: number) {
    this.countdown = seconds;

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.intervalId = setInterval(() => {
      if (this.countdown && this.countdown > 0) {
        this.countdown -= 1;
      } else {
        clearInterval(this.intervalId);
        this.router.navigate(['/home']);
      }
    }, 1000);
  }

  ngAfterViewInit() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      slides: {
        perView: 1,
      },
      drag: false,
      initial: this.initialSlide,
      defaultAnimation: {
        duration: 1000,
      },
      slideChanged: (slider) => {
        if (slider?.track?.details?.abs == slider?.track?.details?.max) {
          this.startCountdown(15);
        }
      },
    });
    this.cdRef.detectChanges();

  }

  ngOnInit(): void {
    this.userForm.get('zipCode')?.valueChanges.subscribe(async (zipCode) => {
      if (zipCode && zipCode.length === 8) {
        try {
          const address = await this.addressService.getAddressByZipCode(
            zipCode
          );
          this.userForm.patchValue({
            address: address.logradouro,
            city: address.localidade,
            state: address.uf,
            neighborhood: address.bairro,
          });
        } catch (error) {
          console.error('Error fetching address:', error);
        }
      }
    });
    this.profileService.getProfileState().subscribe((profile) => {
      if (profile) {
        if (profile.state == 'FINISHED') {
          this.router.navigate(['/home']);
        } else {
          this.provider = 'LOGGED';
          this.userForm.patchValue({
            email: profile.email,
            confirmEmail: profile.email,
            fullName: profile.name,
          });
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }

  async next() {
    let currentStep = this.slider?.track?.details?.abs;
    this.formErrors = {};

    if (currentStep == 0 && !this.provider) {
      const formValidation = formSchema
        .innerType()
        .pick({ email: true, confirmEmail: true })
        .safeParse(this.userForm.value);
      if (!formValidation.success) {
        this.formErrors = formValidation?.error?.formErrors?.fieldErrors;
        return;
      }
    } else if (currentStep == 1) {
      const formValidation = this.provider
        ? formSchema
            .innerType()
            .omit({ password: true, confirmPassword: true })
            .safeParse(this.userForm.value)
        : formSchema.safeParse(this.userForm.value);
      console.log(formValidation, this.userForm.value);
      if (!formValidation.success) {
        this.formErrors = formValidation?.error?.formErrors?.fieldErrors;
        return;
      }
    } else if (currentStep == 2) {
      const formValidation = this.provider
        ? formSchema
            .innerType()
            .omit({ password: true, confirmPassword: true })
            .safeParse(this.userForm.value)
        : formSchema.safeParse(this.userForm.value);
      if (!formValidation.success) {
        this.formErrors = formValidation.error.formErrors.fieldErrors;
        return;
      }

      let body:any = {
        userAddress: {
          zipCode: this.userForm.value.zipCode,
          address: this.userForm.value.address,
          number: this.userForm.value.number,
          complement: this.userForm.value.complement,
          country: 'Brazil',
          neighborhood: this.userForm.value.neighborhood,
          city: this.userForm.value.city,
          state: this.userForm.value.state,
        },
        userInfo: {
          name: this.userForm.value.fullName,
          document: this.userForm.value.document,
          mobile: this.userForm.value.mobile,
          gender: this.userForm.value.gender,
          birthday: this.userForm.value.birthday,
        },
      };

      let signUpResponse:any;
      if (this.provider) {
        signUpResponse = await this.authService.completeSignupSocial(body)
      } else {
        body = {
          ...body,
          provider: 'INTERNAL',
          email: this.userForm.value.email,
          userCredential: {
            password: this.userForm.value.password,
          },
        }
        signUpResponse = await this.authService.signUp(body)
      }

      localStorage.setItem(
        'authToken',
        signUpResponse?.data?.session?.accessToken ||
          signUpResponse?.data?.user?.session?.accessToken
      );
    } else if (currentStep == 3 && !this.provider) {
      const formValidation = codeFormSchema.safeParse(this.userForm.value);
      if (!formValidation.success) {
        this.formErrors = formValidation.error.formErrors.fieldErrors;
        return;
      }

      const code1 = this.userForm.get('code-1')?.value || '';
      const code2 = this.userForm.get('code-2')?.value || '';
      const code3 = this.userForm.get('code-3')?.value || '';
      const code4 = this.userForm.get('code-4')?.value || '';
      const code5 = this.userForm.get('code-5')?.value || '';
      const code6 = this.userForm.get('code-6')?.value || '';

      const concatenatedCodes =
        `${code1}${code2}${code3}${code4}${code5}${code6}`.toUpperCase();
      const result = await this.profileService.verifyEmail(concatenatedCodes);
      if(result.message!="Account validated") {
        this.formErrors = {
          code: ["Código incorreto"]
        }
        return
      }
    }
    this.slider?.next();
  }
}
