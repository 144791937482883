<nav class="w-full text-white pl-10 pr-0 z-50 relative">
  <div class="mx-auto flex justify-start gap-5 items-center">
    <div class="flex items-center my-5">
      <a href="/" class="flex mt-3">
        <img [src]="'./assets/images/logo.svg'" alt="Logo Ensina" class="h-8" />
      </a>
    </div>

    <div class="flex space-x-6 items-center" *ngFor="let menuItem of menuItems">
      <div class="relative group">
        <a [routerLink]="'/'" class="flex items-center justify-center py-4 px-2 text-sm w-[125px] text-nowrap cursor-pointer">
          {{ menuItem.name }}
        </a>
        <div
          [ngClass]="barColor[menuItem.color]"
          class="h-[7px] pointer-events-none mx-auto w-full transition-all duration:1000 ease-linear w-[35px]"
        ></div>
      </div>
    </div>
  </div>
</nav>
