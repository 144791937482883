import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import colors from '../../utils/colors';
import { InstagramIconComponent } from '../icons/instagram-icon';
import { LinkedinIconComponent } from '../icons/linkedin-icon';
import { TikTokIconComponent } from '../icons/tiktok-icon';
import { YoutubeIconComponent } from "../icons/youtube-icon";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, InstagramIconComponent, TikTokIconComponent, LinkedinIconComponent, YoutubeIconComponent],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  
}
