import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import colors from '../../utils/colors';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  barColor: Record<string, string> = {
    primary: 'bg-primary',
    secondary: 'bg-secondary',
    tertiary: 'bg-tertiary',
  }

  floatingMenuColor: Record<string, string> = {
    primary: 'border-primary',
    secondary: 'border-secondary',
    tertiary: 'border-tertiary',
  }

  navItemColor: Record<string, string> = {
    primary: 'text-primary hover:bg-primary',
    secondary: 'text-secondary hover:bg-secondary',
    tertiary: 'text-tertiary hover:bg-tertiary',
  }

  profile: any = null;

  menuItems = [
    {
     name: 'Início',
     color: 'primary',
     children: [
      {
        name: 'Aulas em andamento',
        to: '/'
      },
      {
        name: 'Recomendados',
        to: '/'
      },
      {
        name: 'Destaques',
        to: '/'
      },
      {
        name: 'Top 10',
        to: '/'
      },
      {
        name: 'Meus cursos',
        to: '/'
      },
     ]
    },
    {
      name: 'Categorias',
      color: 'secondary',
      children: [
       {
         name: 'Aulas em andamento',
         to: '/'
       },
       {
         name: 'Recomendados',
         to: '/'
       },
       {
         name: 'Destaques',
         to: '/'
       },
       {
         name: 'Top 10',
         to: '/'
       },
       {
         name: 'Meus cursos',
         to: '/'
       },
      ]
     },

    {
      name: 'Meus cursos',
      color: 'tertiary',
      children: [
       {
         name: 'Aulas em andamento',
         to: '/'
       },
       {
         name: 'Recomendados',
         to: '/'
       },
       {
         name: 'Destaques',
         to: '/'
       },
       {
         name: 'Top 10',
         to: '/'
       },
       {
         name: 'Meus cursos',
         to: '/'
       },
      ]
     }
  ]

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.profileService.getProfileState().subscribe((profile) => {
      this.profile = profile
    });
  }

  logout() {
    localStorage.removeItem("authToken");
    this.router.navigate(["/login"])
  }
}
