import { Component, ElementRef, ViewChild } from '@angular/core';
import { InputComponent } from '../../../shared/components/form/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FacebookCircleIconComponent } from '../../../shared/components/icons/facebook-circle-icon';
import { GoogleCircleIconComponent } from '../../../shared/components/icons/google-circle-icon';
import { AuthHomePageComponent } from '../auth-home-page/auth-home-page.component';
import { AppleCircleIconComponent } from '../../../shared/components/icons/apple-circle-icon';
import { z } from 'zod';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { ProfileService } from '../../../services/profile.service';
import { environment } from '../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { HeaderMinimalComponent } from "../../../shared/components/header-minimal/header-minimal.component";

const formSchema = z.object({
  password: z.string().min(8, 'Minímo de 8 caracteres'),
  confirmPassword: z.string(),
}).superRefine((data, ctx) => {
  if (data.confirmPassword !== data.password) {
    ctx.addIssue({
      code: 'custom',
      path: ['confirmPassword'],
      message: 'Senhas não são iguais',
    });
  }
});


@Component({
  selector: 'app-auth-recovery-page',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    ReactiveFormsModule,
    RouterModule,
    FacebookCircleIconComponent,
    GoogleCircleIconComponent,
    AuthHomePageComponent,
    AppleCircleIconComponent,
    HeaderMinimalComponent
],
  templateUrl: './auth-recovery-page.component.html',
  styleUrl: './auth-recovery-page.component.scss',
})
export class AuthRecoveryPageComponent {
  userForm: FormGroup;
  formErrors: any = {};
  sendPasswordStatus: any = null
  code:any = '';
  expiredIn:any = '';
  now = new Date();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private profileService: ProfileService,
    private route: ActivatedRoute
  ) {
    this.userForm = this.fb.group({
      confirmPassword: [''],
      password: [''],
    });
  }

  async resetPassword() {
    this.formErrors = {}
    const formValidation = formSchema.safeParse(this.userForm.value);
    if (!formValidation.success) {
      this.formErrors = formValidation.error.formErrors.fieldErrors;
      return;
    }

    const body = {
      code: this.code,
      password: this.userForm.value.password,
    };
    this.sendPasswordStatus='loading'
    try {
      const resetResponse = await this.authService.resetPassword(body);
      this.sendPasswordStatus='finished'
    } catch (error) {
      this.sendPasswordStatus=null
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.code = params.get('code');
    });
    this.route.queryParamMap.subscribe(params => {
      try {
        this.expiredIn = new Date(Number(params.get('expiredIn')));
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }

}
