import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';

@Component({
  selector: 'app-header-minimal',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header-minimal.component.html',
  styleUrls: ['./header-minimal.component.css']
})
export class HeaderMinimalComponent {
  barColor: Record<string, string> = {
    primary: 'bg-primary',
    secondary: 'bg-secondary',
    tertiary: 'bg-tertiary',
  }

  floatingMenuColor: Record<string, string> = {
    primary: 'border-primary',
    secondary: 'border-secondary',
    tertiary: 'border-tertiary',
  }

  navItemColor: Record<string, string> = {
    primary: 'text-primary hover:bg-primary',
    secondary: 'text-secondary hover:bg-secondary',
    tertiary: 'text-tertiary hover:bg-tertiary',
  }

  profile: any = null;

  menuItems = [
    {
     name: 'Início',
     color: 'primary'
    }
  ]

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.profileService.getProfileState().subscribe((profile) => {
      this.profile = profile
    });
  }

  logout() {
    localStorage.removeItem("authToken");
    this.router.navigate(["/login"])
  }
}
