<div class="carousel-container">
  <div class="relative overflow-hidden">
    <div
      class="carousel-track flex transition-transform duration-500 ease-in-out"
    >
      <div class="min-w-full flex-shrink-0">
        <div class="relative">
          <img
            [src]="item.image"
            *ngIf="!videoLoaded || !item.video"
            alt="{{ item.title }}"
            class="w-full h-[84vh] max-h-[650px] object-cover"
          />
          <video #video (loadeddata)="onVideoLoaded(video)" [autoplay]="'autoplay'" [loop]="'loop'" [muted]="'muted'" *ngIf="item.video" [ngClass]="{'opacity-0 pointer-events-none': !videoLoaded}" class="w-full h-[84vh] max-h-[650px] object-cover">
            <source [src]="item.video" type="video/mp4" />
          </video>
          <div
            class="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-black via-[#00000077] via-transparent to-transparent h-full"
          ></div>
          <div
            class="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-b from-black to-transparent h-24"
          ></div>
          <div
            class="absolute top-2 left-10 flex flex-col gap-1 items-start z-0"
          >
            <span class="bg-primary rounded-md text-white text-sm px-3 py-1">{{
              item.tag
            }}</span>
            <span class="text-white text-[36px] py-1 font-extralight">
              ORIGINAL
              <span class="ml-2 font-medium">ENSINA </span>
              <span class="text-[52px] mb-[-17px] inline-block align-bottom"
                >®</span
              >
            </span>
            <img
              [src]="'./assets/images/home/hero-1.png'"
              class="w-full max-w-[40%]"
            />
            <div class="flex gap-8 pl-[20%] items-center text-lg w-full">
              <a
                href="#"
                class="text-gray-300 font-extralight hover:scale-110 hover:text-white transition-transform duration-300 ease-linear"
                >Saiba mais</a
              >
              <button
                href="#"
                class="bg-transparent text-primary shrink-border px-4 py-3 rounded-lg"
              >
                Comprar
              </button>
              <div class="ml-auto px-10 flex gap-3 items-center select-none h-20">
                <div class="text-xl border-r border-r-white pr-4">
                  <span class="text-primary font-semibold">Curso: </span>
                  <span class="text-white">Arte</span>
                </div>
                <div class="text-xl border-r border-r-white pr-4">
                  <span class="text-primary font-semibold"
                    >Popularidade: 90%</span
                  >
                </div>
                <div
                  class="flex flex-col gap-1 overflow-hidden text-nowrap items-center justify-center group text-xl text-black inline-block bg-white rounded-md font-semibold p-2 hover:w-[100px] w-14 h-12 text-center transition-all duration-200 ease-linear mr-16 hover:-mr-[-20px] hover:h-20"
                >
                  <span class="text-xs -top-5 relative group-hover:top-0 transition-all duration-300 ease-linear">Horas totais</span>
                  <span>45h</span>
                  <span class="text-xs -bottom-5 relative group-hover:bottom-0 transition-all duration-300 ease-linear">8 módulos</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="absolute cursor-pointer flex flex-col justify-center items-start left-0 top-0 bottom-0 w-[100px] bg-gradient-to-r from-black via-black to-transparent h-50 opacity-0 hover:opacity-100 transition-all duration-200 ease-linear"
    >
      <button
        class="z-10 p-2 h-10 w-fit text-white text-lg ml-4"
      >
        ←
      </button>
    </div>
    <div
      class="absolute cursor-pointer flex flex-col justify-center items-end right-0 top-0 bottom-0 w-[100px] bg-gradient-to-l from-black via-black to-transparent h-50 opacity-0 hover:opacity-100 transition-all duration-200 ease-linear"
    >
      <button
        class="z-10 p-2 h-10 w-fit text-white text-lg mr-4"
      >
        →
      </button>
    </div>
  </div>

</div>
