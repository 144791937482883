import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-hero-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hero-carousel.component.html',
})
export class HeroCarouselComponent {
  currentSlide = 0;
  videoLoaded: boolean = false;

  item = 
    { 
      title: 'NEON LED 1ª e 2ª geração',
      image: './assets/images/hero-1.jpg', 
      description: 'com Vinícius Aquino',
      tag: 'Sugerido para você',
      video: './assets/hero-1.mp4', 
    }

    onVideoLoaded(video: HTMLVideoElement) {
      setTimeout(() => {
        this.videoLoaded = true;
        video.play();
      }, 2000);
    }
}
