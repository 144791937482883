import { Component, Input } from '@angular/core';

@Component({
  selector: 'linkedin-icon',
  template: `
    <svg
      [attr.width]="size"
      [attr.height]="size"
      [class]="class"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.641 30.641"
    >
      <g>
      <path d="M31.727,4H6.581A2.468,2.468,0,0,0,4,6.356v25.2A2.656,2.656,0,0,0,6.581,34.14H31.727a2.524,2.524,0,0,0,2.411-2.581V6.356A2.317,2.317,0,0,0,31.727,4ZM13.418,29.129H9.029V15.7h4.389ZM11.327,13.664a2.223,2.223,0,0,1-2.3-2.317,2.223,2.223,0,0,1,2.336-2.317,2.223,2.223,0,0,1,2.3,2.317,2.223,2.223,0,0,1-2.336,2.317Zm17.8,15.465H24.72V21.783c0-1.752-.622-2.957-2.185-2.957a2.354,2.354,0,0,0-2.2,1.582,2.7,2.7,0,0,0-.151,1.074v7.648H15.848V15.7h4.332v1.884A4.369,4.369,0,0,1,24.136,15.3c2.844,0,4.992,1.884,4.992,5.9Z" transform="translate(-4 -3.999)" fill="currentColor"/>
      </g>
    </svg>
  `,
  standalone: true
})
export class LinkedinIconComponent {
  @Input() size: number = 30;
  @Input() class = '';
   
}