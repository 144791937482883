<nav class="w-full bg-black text-white pl-10 pr-0 z-50 relative">
  <div class="mx-auto flex justify-between items-center">
    <div class="flex items-center my-5">
      <a href="/" class="flex mt-3">
        <img [src]="'./assets/images/logo.svg'" alt="Logo Ensina" class="h-8" />
      </a>
    </div>

    <div class="flex space-x-6 items-center" *ngFor="let menuItem of menuItems">
      <div class="relative group">
        <button class="flex items-center justify-center py-4 px-2 text-sm w-[125px] text-nowrap">
          {{ menuItem.name }}
          <svg
            class="w-4 h-4 ml-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <div
          [ngClass]="barColor[menuItem.color]"
          class="h-[7px] pointer-events-none mx-auto w-full transition-all duration:1000 ease-linear group-hover:w-[35px]"
        ></div>

        <div
          [ngClass]="floatingMenuColor[menuItem.color]"
          class="absolute opacity-0 group-hover:opacity-100 max-h-0 overflow-hidden transition-[max-height] duration-1000 ease-linear group-hover:max-h-[1000px] bg-black text-white mt-2 w-[220px] left-[-45px] border-2 border-t-0 rounded-b-lg"
        >
          <a
            href="#"
            [ngClass]="navItemColor[menuItem.color]"
            *ngFor="let navItem of menuItem.children"
            class="block px-4 py-2 text-primary text-center hover:text-white duration-200 ease-linear transition-all"
            >{{navItem.name}}</a
          >
        </div>
      </div>
    </div>

    <div class="flex space-x-4 items-center self-stretch">
      <input
        type="text"
        class="hidden md:block px-3 py-2 rounded-full bg-gray-200 text-black placeholder-gray-500"
        placeholder="Q"
      />
      <div class="relative group self-stretch">
        <button class="flex bg-[#181C21] items-center justify-center py-4 px-2 text-sm w-[125px] text-nowrap self-stretch h-full">
          <svg
            class="w-4 h-4 ml-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <div
          class="h-[7px] pointer-events-none mx-auto w-full transition-all duration:1000 ease-linear group-hover:w-[35px]"
        ></div>

        <div
          class="absolute opacity-0 group-hover:opacity-100 max-h-0 overflow-hidden transition-[max-height] duration-1000 ease-in group-hover:max-h-[1000px] bg-[#181C21] text-white -mt-2 w-[220px] right-0 rounded-xl py-4"
        >
          <span class="text-sm block mx-5 text-center border-b border-b-[#999999] mb-4 pb-2">{{profile?.name}}</span>
          <span class="font-semibold text-sm ml-5">Sua conta</span>
          <a
            href="#"
            (click)="logout()"
            class="block pl-8 pr-4 py-2 hover:bg-white hover:text-[#181C21] my-2 duration-200 ease-linear transition-all"
            >Sair</a
          >
        </div>
      </div>
    </div>
  </div>
</nav>
