import { CommonModule } from '@angular/common';
import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import KeenSlider from 'keen-slider'


@Component({
  selector: 'app-hover-slide-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hover-slide-carousel.component.html',
  styleUrl:  './hover-slide-carousel.component.css'
})
export class HoverSlideCarouselComponent {
  // @Input() slides: {text: string, color: string}[]
  @ViewChild('sliderRef') sliderRef!: ElementRef<HTMLElement>;

  slider: any = null
  slides = Array.from({ length: 8 });


  ngAfterViewInit(){
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      slides: {
        perView: 5,
        spacing: 15
      },
    })
  }

  ngOnDestroy(){
    if(this.slider) this.slider.destroy()
  }
}
