import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  async getAddressByZipCode(zipCode: string) {
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching address:', error);
      throw error;
    }
  }
}
