import axios from 'axios';
import { environment } from '../../environments/environment';

const API = axios.create({
  baseURL: environment.apiUrl
});

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, please login again');
    }
    return Promise.reject(error);
  }
);

export default API;