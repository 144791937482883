import { Component, Input } from '@angular/core';

@Component({
  selector: 'instagram-icon',
  template: `
    <svg
      [attr.width]="size"
      [attr.height]="size"
      [class]="class"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.641 30.641"
    >
      <g>
        <path d="M22.185,0H8.456A8.465,8.465,0,0,0,0,8.456V22.185a8.465,8.465,0,0,0,8.456,8.456H22.185a8.465,8.465,0,0,0,8.456-8.456V8.456A8.465,8.465,0,0,0,22.185,0Zm5.738,22.185a5.744,5.744,0,0,1-5.737,5.737H8.456a5.743,5.743,0,0,1-5.737-5.737V8.456A5.744,5.744,0,0,1,8.456,2.719H22.185a5.744,5.744,0,0,1,5.737,5.737V22.185Z"  fill="currentColor"/>
        <path d="M48.863,40.97a7.9,7.9,0,1,0,7.9,7.9A7.9,7.9,0,0,0,48.863,40.97Zm0,13.072a5.177,5.177,0,1,1,5.177-5.176A5.183,5.183,0,0,1,48.863,54.042Z" transform="translate(-33.543 -33.545)"  fill="currentColor"/>
        <path d="M120.915,28.251a1.993,1.993,0,1,0,1.41.584A2,2,0,0,0,120.915,28.251Z" transform="translate(-97.368 -23.131)" fill="currentColor" />
      </g>
    </svg>
  `,
  standalone: true
})
export class InstagramIconComponent {
  @Input() size: number = 30;
  @Input() class = '';
   
}