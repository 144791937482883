import { Component, Input } from '@angular/core';

@Component({
  selector: 'apple-circle-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      [attr.width]="size"
      [attr.height]="size"
      [class]="class"
      viewBox="0 0 41 41"
    >
      <g fill="none" stroke="currentColor" stroke-width="1.5">
        <circle cx="20.5" cy="20.5" r="20.5" stroke="none" />
        <circle cx="20.5" cy="20.5" r="19.75" fill="none" />
      </g>
      <g transform="translate(11.688 8.521)">
        <g transform="translate(0)">
          <path
            d="M15.544,0H15.7a4.629,4.629,0,0,1-1.166,3.489,3.614,3.614,0,0,1-3.181,1.493A4.49,4.49,0,0,1,12.538,1.6,5.125,5.125,0,0,1,15.544,0Z"
            transform="translate(-2.572)"
            fill="currentColor"
          />
          <path
            d="M19.729,16.147v.043a12.578,12.578,0,0,1-1.8,3.47c-.686.945-1.527,2.216-3.029,2.216-1.3,0-2.16-.834-3.49-.857-1.407-.023-2.181.7-3.467.879H7.5a3.917,3.917,0,0,1-2.262-1.559A13.656,13.656,0,0,1,2.1,12.482v-.967a6.335,6.335,0,0,1,2.766-5.2,4.672,4.672,0,0,1,3.136-.726,7.89,7.89,0,0,1,1.537.44,4.244,4.244,0,0,0,1.536.46,3.687,3.687,0,0,0,1.077-.329,6.629,6.629,0,0,1,3.467-.615,4.81,4.81,0,0,1,3.534,2.107,4.535,4.535,0,0,0-2.3,4.5A4.645,4.645,0,0,0,19.729,16.147Z"
            transform="translate(-2.104 -0.278)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  `,
  standalone: true,
})
export class AppleCircleIconComponent {
  @Input() size: number = 30;
  @Input() class = '';
}
